import { ProgramNavList } from '@type/buzzkuri/api'
import { ReactNode, useState } from 'react'
import { ProgramNavListContext } from './Cotenxt'

type Props = {
  children: ReactNode
}

export function ProgramNavListProvider({ children }: Props): JSX.Element {
  const [programNavList, setProgramNavList] = useState<ProgramNavList>({
    totalProgramCount: 0,
    programCategories: {
      objectives: [],
      genres: [],
      tags: [],
    },
  })

  return (
    <>
      <ProgramNavListContext.Provider
        value={{ ...programNavList, setProgramNavList }}
      >
        {children}
      </ProgramNavListContext.Provider>
    </>
  )
}
