import { ProgramNavList } from '@type/buzzkuri/api'
import { createContext, Dispatch, SetStateAction, useContext } from 'react'

export type ProgramNavListContextType = ProgramNavList & {
  setProgramNavList: Dispatch<SetStateAction<ProgramNavList>>
}

const defaultValues: ProgramNavListContextType = {
  totalProgramCount: 0,
  programCategories: {
    objectives: [],
    genres: [],
    tags: [],
  },
  setProgramNavList: () => {
    /** */
  },
}

export const ProgramNavListContext =
  createContext<ProgramNavListContextType>(defaultValues)

export function useProgramNavList(): ProgramNavListContextType {
  return useContext(ProgramNavListContext)
}
