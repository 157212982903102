import { ProgramNavListContext } from '@context/ProgramNavList/Cotenxt'
import { ProgramCategories } from '@type/buzzkuri/api'
import { addJumpId, addParams, getUrlUnit } from '@utils/url'
import { useRouter } from 'next/router'
import { ReactNode, useContext, useEffect, useState } from 'react'
import {
  DEFAULT_PROGRAMS_PATHS,
  ProgramsPaths,
  ProgramsPathsContext,
} from './Context'

type Props = {
  children: ReactNode
}

export function ProgramsPathsProvider({ children }: Props): JSX.Element {
  const router = useRouter()
  const [programsPaths, setProgramsPaths] = useState<ProgramsPaths>(
    DEFAULT_PROGRAMS_PATHS
  )
  const [transitionalPath, setTransitionalPath] = useState<string>()
  const { programCategories } = useContext(ProgramNavListContext)

  const init = () => {
    setTransitionalPath('')
    setProgramsPaths(DEFAULT_PROGRAMS_PATHS)
  }

  useEffect(() => {
    const validPaths = Object.values(programsPaths).filter((v) => v)
    // いずれかのカテゴリが選択済み
    if (validPaths.length > 0) {
      const nextPath = `/programs/${validPaths.join('/')}`
      const { asPath } = router
      const { path, params, jumpId } = getUrlUnit(decodeURI(asPath))

      // 同じパスへの遷移を防ぐ
      if (transitionalPath === undefined) {
        setTransitionalPath(nextPath)
      } else if (transitionalPath !== nextPath) {
        // 遷移先のパスと現在のパスが同じ場合、パラメータとジャンプ先のIDがある場合に消さない
        // 遷移先を格納しているstateにparams等があるとページ遷移が不安定になるため、paramsは遷移する時だけ利用し状態には保存しない
        const additionalParams =
          nextPath === path ? `${addParams(params)}${addJumpId(jumpId)}` : ''
        router
          .push(`${nextPath}${additionalParams}`)
          .then(() => setTransitionalPath(nextPath))
      }
    } else if (transitionalPath && programCategories) {
      // 掛け合わせページに遷移あり AND どのカテゴリも選択されていない
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { tags, ...rest } = programCategories
      const programCategoryList: Partial<
        ProgramCategories['programCategories']
      > = { ...programCategories }
      delete programCategoryList.tags
      const isReset =
        Object.values(rest)
          .flat()
          .map(({ name }) => name)
          .filter((str) => decodeURI(router.asPath).includes(str)).length > 0
      if (isReset) {
        router.push('/programs')
      }
    }
  }, [programCategories, programsPaths, router, transitionalPath])

  const value = {
    programsPaths,
    setProgramsPaths,
    init,
  }

  return (
    <>
      <ProgramsPathsContext.Provider value={value}>
        {children}
      </ProgramsPathsContext.Provider>
    </>
  )
}
